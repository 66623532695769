import React from 'react';
import { version } from 'feather-icons/package.json';
import { Link as GatsbyLink, navigateTo } from 'gatsby';
import { Border, Box, Flex, Button, Divider, Text, Container } from 'rebass';
import Hide from 'hidden-styled';
import styled from 'styled-components';
import { Location } from '@reach/router';
import logOutboundLink from '../utils/log-outbound-link';
import Link from './Link';
import Img from 'gatsby-image';
import Icon from './Icon';
import producthunt from '../images/producthunt.svg';
import indiehackers from '../images/indiehackers.svg';
import twitter from '../images/twitter.svg';
import youtube from '../images/youtube.svg';
import youtube_badge from '../images/youtube_badge.svg';
import { colors } from '../theme';

const HeaderLink = props => <Link ml={[4, 5]} {...props} />;

const NewsletterLink = styled.a`
  color: ${colors.gray9};
  margin-left: 24px;
  margin-right: 24px;
  &:hover {
    color: ${colors.base};
    cursor: pointer;
  }
`;

const SocialLink = styled(Link)`
  margin-left: 5px;
  padding-right: 5px;
`;
const SocialIcon = styled(Icon)`
  color: #dfe1e6;
  width: 20px;
  margin-bottom: -2px;
  &:hover {
    color: gray;
  }
`;

const SocialImage = styled.img`
  color: #dfe1e6;
  width: 20px;
  margin-bottom: -4px;
  &:hover {
    transform: translate(1px, 1px);
  }
`;

const NavBorder = Border.extend`
  border-bottom-width: 10px;
  border-color: #dee1e5;
`;

class Header extends React.Component {
  // set menu to either open to the menu page if it's not currently open, or if it is, to close it.

  // currently menu doesnt close, actually navigates back home. Need to find
  renderMenu() {
    // console.log(location);
    const checkDefine = !location ? null : location.pathname;
    if (checkDefine === '/menu') {
      console.log('Go Back');
      return (
        <div>
          <HeaderLink onClick={() => navigateTo('/')}>Close</HeaderLink>
        </div>
      );
    }
    return (
      <div>
        <HeaderLink is={GatsbyLink} to="/menu">
          Menu
        </HeaderLink>
      </div>
    );
  }

  render() {
    return (
      <div>
        <NavBorder py={5}>
          <Container>
            <Flex align="center">
              <Text
                is={GatsbyLink}
                to="/"
                fontSize={4}
                color="gray9"
                fontWeight="bold"
              >
                Launching Myself 🚀
              </Text>

              <Box mx="auto" />
              <Hide xs>
                <HeaderLink
                  is={GatsbyLink}
                  to="/about"
                  activeStyle={{ fontWeight: 'bold' }}
                >
                  About
                </HeaderLink>
                {/* <HeaderLink
                  is={GatsbyLink}
                  to="/algorithm"
                  activeStyle={{ fontWeight: 'bold' }}
                >
                  Algorithm
                </HeaderLink> */}

                <HeaderLink
                  is={GatsbyLink}
                  to="/tools"
                  activeStyle={{ fontWeight: 'bold' }}
                >
                  Toolkit
                </HeaderLink>
                <HeaderLink
                  is={GatsbyLink}
                  to="/community"
                  activeStyle={{ fontWeight: 'bold' }}
                >
                  Community
                </HeaderLink>
                <NewsletterLink
                  data-formkit-toggle="304ed3d90e"
                  href="https://pages.convertkit.com/304ed3d90e/c61a263e4e"
                >
                  Newsletter
                </NewsletterLink>
              </Hide>
              <Hide xs sm>
                <SocialLink href="http://twitter.com/brentsum" target="_blank">
                  <SocialImage src={twitter} />
                </SocialLink>
                <SocialLink
                  href="https://www.youtube.com/c/BrentSummersLaunches"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialImage src={youtube_badge} />
                </SocialLink>
                <SocialLink
                  href="https://www.producthunt.com/@brentsum"
                  target="_blank"
                >
                  <SocialImage src={producthunt} />
                </SocialLink>
                <SocialLink
                  href="http://indiehackers.com/user/brentsum"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialImage src={indiehackers} />
                </SocialLink>
              </Hide>

              <Hide sm md lg>
                <Location>
                  {({ location }) => {
                    const checkDefine = !location ? null : location.pathname;
                    if (checkDefine === '/menu') {
                      // console.log('Go Back');
                      return (
                        <div>
                          <HeaderLink onClick={() => navigateTo('/')}>
                            Close
                          </HeaderLink>
                        </div>
                      );
                    }
                    return (
                      <div>
                        <HeaderLink is={GatsbyLink} to="/menu">
                          Menu
                        </HeaderLink>
                      </div>
                    );
                  }}
                </Location>
              </Hide>
            </Flex>
          </Container>
        </NavBorder>
      </div>
    );
  }
}

export default Header;
