import React, { Component } from 'react';
import ToolsBanner from '../components/ToolsBanner';
import ToolsBody from '../components/ToolsBody';
import Page from '../components/Page';
import { graphql } from 'gatsby';

class AboutPage extends Component {
  render() {
    return (
      <div>
        <Page>
          <ToolsBanner header={this.props.data.contentfulPages.header} />
          <ToolsBody content={this.props.data.contentfulPages} />
        </Page>
      </div>
    );
  }
}

export default AboutPage;

export const query = graphql`
  query ToolsQuery {
    contentfulPages(title: { eq: "Tools" }) {
      id
      header
      bodyTitle
      body {
        body
        childMarkdownRemark {
          id
          html
        }
      }
    }
  }
`;
