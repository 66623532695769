import React from 'react';
import { Flex } from 'rebass';

import logOutboundLink from '../utils/log-outbound-link';
import Link from './Link';
import styled from 'styled-components';
import Text from './Text';

const FooterText = styled(Text)`
  font-size: 0.8em;
`;

class Footer extends React.Component {
  render() {
    const getYear = new Date().getFullYear();
    return (
      <Flex direction="column" justifyContent="center" pt="150px" mb="10px">
        <Link
          href="https://twitter.com/brentsum"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => logOutboundLink('made by @brentsum')}
        >
          <FooterText> {getYear} - Made by @brentsum </FooterText>
        </Link>
      </Flex>
    );
  }
}

export default Footer;
