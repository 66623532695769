import React from 'react';
import styled from 'styled-components';
import { Box, Row, Banner, Heading, Subhead, Column } from 'rebass';
import tools from '../images/tools.svg';
import LazyLoad from 'react-lazy-load';
import Container from '../components/Container';
import Hide from 'hidden-styled';

class ToolsBanner extends React.Component {
  render() {
    const BannerHome = Banner.extend`
      min-height: 300px;
      max-height: 40vh;
      background: 'base';
      text-decoration: none;
      color: ${this.props.colorFont};
    `;

    const BlockImage = styled.img`
      width: 200px;
      margin-left: auto;
      margin-right: auto;
    `;
    return (
      <BannerHome
        bg="#C1292E"
        // backgroundImage={this.props.bgImage}
        className="BannerHome"
      >
        <Container py="10vh" my={4}>
          <Row mx="auto">
            <Column alignSelf="center">
              <Heading
                textAlign="left"
                is="h5"
                fontSize={[5, 4, 5]}
                color="white"
              >
                {this.props.header}
              </Heading>
              {/* <Subhead
                  fontSize={[3, 4, 3]}
                  color="#dee1e5"
                  fontWeight="initial"
                >

                </Subhead> */}
            </Column>
            <Hide xs>
                <Column>
                <LazyLoad height="190px" width="250px">
                    <BlockImage src={tools} alt="tools" />
                  </LazyLoad>
              </Column>
              </Hide>
          </Row>
        </Container>
      </BannerHome>
    );
  }
}

export default ToolsBanner;
