import React, { Fragment } from 'react';
import { func, shape, string } from 'prop-types';
import Helmet from 'react-helmet';
import { injectGlobal } from 'styled-components';
import { Provider } from 'rebass';
import theme from '../theme';
import Header from '../components/Header';
import Footer from '../components/Footer';
import dripsnip from '../utils/drip';
import { StaticQuery, graphql } from 'gatsby';

const propTypes = {
  children: func.isRequired,
  data: shape({
    site: shape({
      siteMetadata: shape({
        title: string,
        description: string,
        siteUrl: string,
      }),
    }),
  }).isRequired,
};

/* eslint-disable no-unused-expressions */

injectGlobal`
  *,
  *:before,
  *:after {
    transition: inherit;
  }

  body {
    margin: 0;
    transition: color 0.15s, background-color 0.15s, box-shadow 0.15s;
  }

  a {
    text-decoration: none;
  }
  #drip-130193 {
    font-family: system-ui, sans-serif;
  }

`;

// if article
//         <Page
// type="article"
// postTitle={title}
// postPreview={preview}
// postImage={httpURL}
// >

// <title>{`${title} | ${data.site.siteMetadata.title}`}</title>
// <meta name="description" content={preview} />
// <meta name="image" content={`https://${firstURL}`} />
// <meta
//   property="og:title"
//   content={`${title} | ${data.site.siteMetadata.title}`}
// />
// <meta property="og:image" content={`https://${firstURL}`} />
// <meta property="og:description" content={preview} />
// <meta name="twitter:card" content="summary_large_image" />
// <meta name="twitter:creator" content="@BrentSum" />
// <meta
//   name="twitter:title"
//   content={`${title} | ${data.site.siteMetadata.title}`}
// />
// <meta name="twitter:description" content={preview} />
// <meta name="twitter:image" content={`https://${firstURL}`} />

export default ({ children, location, props }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
          }
        }
      }
    `}
    render={data => (
      <div>
        <Provider theme={theme}>
          <Helmet>
            <html lang="en" />

            <title>{data.site.siteMetadata.title}</title>

            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />

            <meta property="og:title" content={data.site.siteMetadata.title} />
            <meta
              property="og:image"
              content="http://launchingmyself.com/screenshot.jpg"
            />
            <meta
              property="og:description"
              content={data.site.siteMetadata.description}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@brentsum" />
            <meta
              name="twitter:image"
              content="http://launchingmyself.com/screenshot.jpg"
            />

            <link
              rel="icon"
              type="image/png"
              href="favicon-32x32.png"
              sizes="32x32"
            />
            <link
              rel="icon"
              type="image/png"
              href="favicon-16x16.png"
              sizes="16x16"
            />
          </Helmet>
          <Header location={location} />
          <main>{children}</main>
          <Footer />
        </Provider>
      </div>
    )}
  />
);
